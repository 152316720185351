import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderApiService } from '../../service/order-api.service';
import { ResponseData, formBuilderData } from 'src/app/helper/interface/response';
import { RESPONSE_CODE } from 'src/app/helper/class/app-constant';
import { ModalService } from 'src/app/shared/service/modal.service';
import { AuthService } from 'src/app/helper/service/auth.service';
import { FormGeneratorComponent } from 'src/app/shared/form/component/form-generator/form-generator.component';
import { AlertService } from 'src/app/helper/service/alert.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrl: './order-info.component.scss'
})
export class OrderInfoComponent implements OnInit {

  orderId: any;
  orderData: any;
  isModal: boolean = false;
  isLogVisible: boolean = false
  currentUser = this.auth.currentUserValue;
  approveFormData: formBuilderData[] = [{ colName: 'pay_amount', title: 'Pay Amount' }, { colName: 'remark', title: 'Remarks' }];
  pageLoading: boolean = false;
  public onClose: Subject<boolean> = new Subject();
  pageInfo = { title: 'Order Info' }
  @ViewChild('approveForm') approveForm: FormGeneratorComponent | undefined;
  constructor(
    private orderApiService: OrderApiService,
    private modalService: ModalService,
    private auth: AuthService,
    private alertService: AlertService,private orderApi: OrderApiService
  ) { }

  ngOnInit(): void {

  }

  setInput(data) {
    this.isModal = true;
    if (data?.id) {
      this.orderId = data.id;
      this.pageLoading=true
      setTimeout(()=>{

        this.getDetails()
      },1000)
    }

  }

  getDetails() {
    if (!this.orderId) {
      return;
    }
    this.pageLoading = true;
    this.orderApi.getOrderById(this.orderId).then((res: ResponseData) => {
      if (res.statusCode == RESPONSE_CODE.SUCCESS) {
        this.orderData = res.result;
        console.log(this.orderData,'order');
        
        setTimeout(() => {
          if (!+this.balanceAmount) {
            this.approveForm?.toogleDisableByCtrl('pay_amount', true);
          } else {
            this.approveForm?.setValue('pay_amount', this.balanceAmount);
          }
        }, 1000);
      }
    })
      .finally(() => this.pageLoading = false)
  }

  getTotal(arr: any[], col) {
    return Array.isArray(arr) ? arr?.reduce((acc, obj) => {
      return acc + (+obj[col])
    }, 0) : 0
  }

  get balanceAmount() {
    const total_amount = this.getTotal(this.orderData?.items, 'total_amount'),
      total_payment = this.getTotal(this.orderData?.payment, 'pay_amount');
    return (total_amount - total_payment) || 0
  }

  close(status = false) {
    if (this.isModal) {
      this.onClose.next(status);
      this.modalService.close();
    }
  }

  hasDeliverPermission() {
    const per = this.auth.checkPermission('ORDER', 'ADD');
    return per && this.orderData?.status != 6
  }

  approve(isDeliver = false) {
    const formValue = this.approveForm?.apiPayload();
    if (+this.balanceAmount && !+formValue?.pay_amount) {
      this.alertService.showToast('Pay Amount is Required')
      return
    }
    if (+this.balanceAmount != +formValue?.pay_amount) {
      this.alertService.showToast('Balance and Payment Amount do not match')
      return
    }
    this.orderApiService.orderUpdateStatus(this.apiPayload(isDeliver ? 6 : null)).then((res: ResponseData) => {
      if (res.statusCode == RESPONSE_CODE.SUCCESS) {
        this.alertService.showToast('Order Update Successfully', 'success');
        this.close(true);
      }
    })
  }

  apiPayload(status: any = null) {
    const payload = this.approveForm?.apiPayload() || {};
    payload.order_id = this.orderData.order_id;
    if (status) {
      payload.status = status;
    } else {
      payload.status = +this.orderData?.status + 1;
    }
    return payload
  }
}
