import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { isEmptyObj } from 'src/app/helper/class/utilityHelper';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  constructor(private http: HttpClient) { }


  getListOrder(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`order/getList/${lasyP}`));
  }

  saveOrder(data: any) {
    return lastValueFrom(this.http.post('order/save', data))
  }

  getOrderById(id) {
    return lastValueFrom(this.http.get(`order/getDetails/${id}`));
  }


  orderUpdateStatus(data) {
    return lastValueFrom(this.http.post(`order/orderUpdateStatus`, data));
  }
  generateVendorOrder(eventId) {
    return lastValueFrom(this.http.get(`vendor/eventOrderList/${eventId}`));
  }
  vendorOrderGenerate(data) {
    return lastValueFrom(this.http.post(`vendor/vendorOrderGenerate`, data));
  }
  getListVendorOrder(ev) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`vendor/getOrderList/${lasyP}`));
  }
  getListPayment(ev) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`payment/getList/${lasyP}`));
  }
  getVendorOrderById(id) {
    return lastValueFrom(this.http.get(`vendor/getVendorOrderItems/${id}`));
  }
  vendorApproveOrder(data) {
    return lastValueFrom(this.http.post(`vendor/orderUpdateStatus`, data));
  }
  saveVendorOrder(data) {
    return lastValueFrom(this.http.post(`vendor/saveVendorOrder`, data));
  }
  updateVendorOrderPayment(data) {
    return lastValueFrom(this.http.post(`vendor/updateVendorOrderPayment`, data));
  }
  updateOrderPayment(data) {
    return lastValueFrom(this.http.post(`order/updateOrderPayment`, data));
  }
}
