<app-page-breadcrumb [pageInfo]="{title:'Order Info',style:{'background-color':'#f2dfa7','padding':'10px 2rem'}}" [isModal]="isModal" />
<div class="modal-body">
    @if (!pageLoading) {
    <div class="d-flex justify-content-end pe-5">
        <small (click)="isLogVisible = true" class="me-2 btn btn-outline-secondary btn-sm"> <i class="pi pi-history"
                style="vertical-align: text-bottom;"></i>
            OpenLog
        </small>
        <div style="font-size: 15px;" class="badge p-2 bg-{{orderData?.color}}">
            {{orderData?.order_statusName}}
        </div>
    </div>
    <div class="row d-flex justify-content-between p-3" >
        <div class="p-2 col-4 card ">
            <table class="table">
                <th>Customer Details</th>
            </table>
    
            <div class=" ms-1" >
                <p class="h6">
                    <span class="font-weight-600">Customer Name : </span>
                    <span class="text-muted">{{orderData?.customer_name}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Customer ID : </span>
                    <span class="text-muted">{{orderData?.customer_id}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Mobile No : </span>
                    <span class="text-muted">{{orderData?.customer_mobile_no}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Email ID : </span>
                    <span class="text-muted">{{orderData?.email_id}}</span>
                </p>
            </div>
        </div>

        <div class="p-2 col-4 card ">
            <table class="table">
                <th> Gospel Event Details</th>
            </table>
           
            <div class=" ms-1" *ngIf="orderData?.event_code">
                <p class="h6">
                    <span class="font-weight-600">Event Name : </span>
                    <span class="text-muted">{{orderData?.eventName}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Event Code : </span>
                    <span class="text-muted">{{orderData?.event_code}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Alliance Partner : </span>
                    <span class="text-muted">{{orderData?.ap_name}}</span>
                </p>
            </div>
        </div>
        <div class="p-2 col-4 card ">
            <table class="table">
                <th>Office Details</th>
            </table>
    
            <div class=" ms-1">
                <p class="h6">
                    <span class="font-weight-600">Office ID : </span>
                    <span class="text-muted">{{orderData?.office_id}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Initial Deposit : </span>
                    <span class="text-muted">{{orderData?.office_deposit |currencyFormat}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Office Balance : </span>
                    <span class="text-muted">{{orderData?.office_balc | currencyFormat}}</span>
                </p>
                <p class="h6">
                    <span class="font-weight-600">Office C or M : </span>
                    <span class="text-muted">{{orderData?.office_cm }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="mb-2 p-2 card">
        <h6 class="my-2 ">Product Details</h6>
        <table class="table-active table">
            <tr>
                <th>#</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Amount </th>
                <th>Proceesing Fees </th>
                <th>Total</th>
            </tr>
            <tbody>
                <tr *ngFor="let a of orderData?.items;index as i">
                    <td>{{i+1}}</td>
                    <td>{{a?.productName}}</td>
                    <td>{{a?.quantity}}</td>
                    <td>{{a?.amount | currencyFormat}}</td>
                    <td>{{a?.processing_fee | currencyFormat}}</td>
                    <td>{{a?.total_amount | currencyFormat}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card">
        <div class="card-body row">
            <h6 class="my-2 ">Payment Details</h6>
            <div class="col-6">
                @if(orderData?.payment){
                <table class="table-active table ">
                    <tr>
                        <th>#</th>
                        <th>Amount</th>
                        <th>Created At</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let a of orderData?.payment;index as i">
                            <td>{{i+1}}</td>
                            <td>{{a?.pay_amount}}</td>
                            <td>{{a?.created_at | date}}</td>
                        </tr>
                    </tbody>
                </table>
                }
            </div>
            <div class="col-6 text-end">
                <p>
                    <small class="font-weight-500">Total Amount : </small>
                    <!-- <span>{{getTotal(orderData?.items,'total_amount')|currencyFormat}}</span> -->
                    <span>{{orderData?.total_amount|currencyFormat}}</span>

                </p>
                <p>
                    <small class="font-weight-500">Paid Amount : </small>
                    <!-- <span>{{getTotal(orderData?.payment,'pay_amount')|currencyFormat}}</span> -->
                    <span>{{orderData?.total_pay_amount|currencyFormat}}</span>

                </p>
                <p>
                    <small class="font-weight-500">Balance Amount : </small>
                    <!-- <span>{{balanceAmount|currencyFormat}}</span> -->
                    <span>{{orderData?.total_amount-orderData?.total_pay_amount|currencyFormat}}</span>

                </p>
            </div>
            <!-- @if(hasApprovePermission()){
            <app-form-generator #approveForm [formData]="approveFormData" />
            }
            <div class="text-center">
                @if(hasApprovePermission()){
                <button class="btn btn-success me-2" (click)="approve()">Approve</button>
                }
                <button class="btn btn-danger" (click)="close()">Close</button>
            </div> -->
            @if(hasDeliverPermission() ){
            <app-form-generator #approveForm [formData]="approveFormData" />
            }
            <div class="text-center">
                @if(hasDeliverPermission() ){
                <p-button label="Delivered" severity="success" class="me-2" (click)="approve(true)"
                    icon="pi pi-check-circle"></p-button>
                }
                <p-button label="Close" severity="danger" (click)="close()" icon="pi pi-minus-circle"></p-button>
            </div>
        </div>
    </div>
    }
    <app-page-loader [loading]="pageLoading" />
</div>

<p-dialog header="Order Log" [(visible)]="isLogVisible" [dismissableMask]="true" position="top" [modal]="true"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    @if (orderData?.tracking?.length) {
    <p-timeline [value]=" orderData?.tracking">
        <ng-template pTemplate="content" let-d>
            <span class="badge bg-{{d?.color || 'primary'}}">
                {{ d?.order_statusName }}
            </span>
            @if(d?.user_name){
            <p class="my-1 mt-2"><small><strong>By {{d?.user_name}}</strong></small></p>
            }
            @if(d?.remark){
            <p class="my-1"><small>{{d?.remark}}</small></p>
            }
        </ng-template>
        <ng-template pTemplate="opposite" let-d>
            <small class="p-text-secondary">{{ d?.updated_at |date:'short' }}</small>
        </ng-template>
    </p-timeline>
    }@else {
    <div class="p-3 text-center">
        <span>No Data Found</span>
    </div>
    }
</p-dialog>